var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{key:_vm.componentKey,ref:"giftCard",staticClass:"collezionamento-card product-card",attrs:{"outlined":"","id":`collezionamento-card-${_vm.product.slug}`}},[_c('div',{staticClass:"product"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex flex-column body"},[_c('img',{staticClass:"product-img align-self-center",attrs:{"src":_vm.product.mediaURL,"onerror":"this.onerror=null;this.src='/no-icon.png'","alt":'Immagine' + _vm.product.name,"title":_vm.product.name + ' (' + _vm.product.codInt + '-' + _vm.product.codVar + ')'}}),_c('div',{staticClass:"description default--text d-flex flex-column justify-left w-100 mt-3 ml-1 ml-sm-0"},[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.product.name)+" ")]),(_vm.showPoints)?_c('span',{staticClass:"stamps mb-1"},[_vm._v(" "+_vm._s(_vm.product.productInfos["POINT_CARD_AMOUNT"])+" "+_vm._s(_vm.$t("common.stamps"))+" + ")]):_vm._e(),(_vm.product.price && _vm.product.price > 0.01)?_c('ProductPrice',{attrs:{"product":_vm.product}}):_vm._e(),(
              _vm.showPoints &&
                _vm.product.warehousePromo &&
                _vm.product.warehousePromo.selectable
            )?_c('span',{staticClass:"stamps mb-1"},[_vm._v(" oppure "+_vm._s(_vm.product.productInfos["POINT_CARD_AMOUNT"])+" "+_vm._s(_vm.$t("common.stamps"))+" + ")]):_vm._e(),_c('div',{staticClass:"product-price points-price"},[(
                _vm.product.warehousePromo && !_vm.product.warehousePromo.selectable
              )?_c('span',{staticClass:"points-qty",domProps:{"innerHTML":_vm._s(_vm.product.warehousePromo.view.footer)}}):(
                _vm.product.warehousePromo && _vm.product.warehousePromo.selectable
              )?_c('span',{staticClass:"points-qty",domProps:{"innerHTML":_vm._s(_vm.product.warehousePromo.view.header)}}):_vm._e()])],1)])])]),_c('div',{staticClass:"card-bottom"},[(_vm.product.available > 0)?_c('div',{staticClass:"actions"},[_c('div',{staticClass:"qty-wrap not-empty"},[_c('v-btn',{staticClass:"minus rounded",attrs:{"large":"","aria-label":"Diminuisci quantità","depressed":"","disabled":_vm.quantity <= 0},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.minus(true)}}},[_c('v-icon',[_vm._v("$minus")])],1),_c('div',{staticClass:"val-cnt"},[_c('span',{staticClass:"val"},[_vm._v(_vm._s(_vm.quantity)+_vm._s(_vm.unit))])]),_c('v-btn',{staticClass:"plus rounded",attrs:{"large":"","aria-label":"Aumenta quantità","depressed":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.plus(true, { checkout: true })}}},[_c('v-icon',[_vm._v("$plus")])],1)],1)]):_c('div',{staticClass:"actions justify-center align-center text-center text-caption font-weight-bold notAvailable"},[_vm._v(" "+_vm._s(_vm.$t("product.notActive"))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }