var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.products && _vm.products.length > 0)?_c('div',{staticClass:"slider product-slider collezionamento-slider pa-2 pb-3 pb-sm-6",class:this.cols == 'auto' ? 'slider-auto' : 'product-grid'},[_vm._t("default",function(){return [_c('h2',{domProps:{"innerHTML":_vm._s(_vm.title)}})]}),_c('swiper',{ref:"swiperRef",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.availableProducts),function(product){return _c('swiper-slide',{key:product.productId},[_c('CollezionamentoCard',{attrs:{"product":product},on:{"selectProduct":function($event){return _vm.$emit('selectProduct', { product: product })}}})],1)}),1),_c('swiper-paginator',{attrs:{"showBullets":_vm.showBullets,"showArrows":_vm.showArrows,"paginationClass":_vm.paginationClass,"length":_vm.availableProducts.length}}),(_vm.showMore)?_c('div',{staticClass:"text-right show-more"},[_c('v-btn',{staticClass:"primary--text text-none",attrs:{"link":"","text":"","to":_vm.categoryPath
            ? {
                name: 'Category',
                params: { pathMatch: _vm.categoryPath },
                query: { categoryId: _vm.categoryId }
              }
            : {
                name: 'Category',
                params: {
                  pathMatch: _vm.proposal.slug
                }
              }}},[_vm._v(" mostra tutti "),_c('v-icon',[_vm._v("$chevronRight")])],1)],1):_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }