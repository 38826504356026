<template>
  <div class="payment-container">
    <v-container class="payment">
      <category-block
        :target="category"
        position="position1"
        class="category-block category-block-1"
      />
      <div class="checkout-title">
        <CategoryTitle
          :category="category"
          :showOnMobile="true"
          :centered="$vuetify.breakpoint.xs"
        />
      </div>
      <v-row>
        <v-col cols="12" md="4" lg="4" order="1" order-md="2" class="pt-0">
          <CheckoutSummary class="accent mb-6 mx-0" :orderCart="cart" />
        </v-col>
        <v-col cols="12" md="8" lg="8" class="pt-0" order="2" order-md="1">
          <v-row>
            <v-col cols="12" sm="6">
              <CartInfoAddressCard
                class="w-100 cart-info-address-card"
                :shippingAddress="cart.shippingAddress"
                :editable="false"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <CartInfoTimeslotCard
                class="w-100 cart-info-timeslot-card"
                :shippingAddress="cart.shippingAddress"
                :timeslot="cart.timeslot"
                :editable="false"
                :value="timeslot"
              />
            </v-col>
          </v-row>
          <PortInformations
            v-if="cart.shippingAddress.addressTypeId == 6"
            @portInfoCompleted="portInfoFormCompleted"/>
          <div
            class="other-informations grey lighten-1 pa-4 mt-6 rounded-lg"
            v-if="cart.shippingAddress.addressTypeId == 2"
          >
            <h3 class="my-1 text-uppercase">
              {{ $t("paymentInvoice.additionalInfo") }}
            </h3>
            <!-- Notes -->
            <label v-html="`${$t('paymentInvoice.notes')}`" />
            <v-text-field
              :value="cart.cartInfos.notes"
              :placeholder="`${$t('paymentInvoice.notes')}`"
              hide-details="auto"
              maxlength="70"
              solo
              @blur="processOnboardChange('notes', $event.target.value)"
            />
          </div>
          <CollezionamentoSlider
            :timeslotId="cart.timeslot.date + cart.timeslot.timeslotId"
            :userId="cart.user.userId"
            :warehouseId="cart.warehouse.warehouseId"
            :layout="36"
            :limit="24"
            :lg="3"
            :xl="4"
            :paginationClass="'swiper-pagination-linea-220'"
            :showArrows="!$vuetify.breakpoint.xsOnly"
            :showBullets="$vuetify.breakpoint.xsOnly"
          >
            <h2 class="my-3 font-weight-regular text-uppercase">
              {{ $t("product.rewards") }}
            </h2>
          </CollezionamentoSlider>
          <div class="mb-3 px-3 px-sm-0">
            <PaymentInvoice @billingFormCompleted="billingFormCompleted" />
            <PackagesTypeList
              :showTermal="
                cart.shippingAddress.deliveryServiceId != 2 &&
                  !(
                    cart.warehouse.metaData &&
                    cart.warehouse.metaData.warehouse_info &&
                    cart.warehouse.metaData.warehouse_info.HIDE_SPECIAL_PACKAGES
                  )
              "
            />
            <PaymentTypeList
              :options="{
                showRememberCard: true,
                rememberCardDefaultValue: true,
                showPaymentMethodImage: true,
                showCardLogos: false,
                showPrebilledAmountInfoText: true,
                mainBackgroundColor: 'transparent',
                confirm: { color: 'primary' },
                back: {
                  outline: true,
                  class: 'primary--text'
                },
                titleStyle: 'secondary--text text--darken-2 text-h3 mb-2'
              }"
              :isPaymentDisabled="
                !billingFormCompletedInternal || !portInfoFormCompletedInternal
              "
              :paymentNameToPaymentInfo="true"
              :showAddNewCardWarningInfo="false"
            /></div
        ></v-col>
      </v-row>
      <category-block
        :target="category"
        position="position2"
        class="category-block category-block-2"
      />
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.payment {
  .cart-info-timeslot-card,
  .cart-info-address-card {
    pointer-events: none;
  }
  .v-stepper {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      max-width: 80%;
    }
  }
  .cart-info-timeslot-card {
    .value {
      text-transform: uppercase;
    }
  }
  .categoryDescription {
    .content {
      background-color: $white;
    }
    :deep(h2) {
      font-size: 14px;
      background-color: var(--v-default-lighten1);
      text-transform: uppercase;
      padding: 10px;
      line-height: 1.5;
    }
    :deep(p) {
      padding: 10px;
      font-size: 14px;
      color: var(--v-default-base);
    }
  }

  .payment-methods {
    background-color: var(--v-grey-lighten1) !important;
    padding: 16px !important;
    border-radius: 8px !important;
    :deep(p) {
      margin-bottom: 0px;
    }
    :deep(.text-h3) {
      font-weight: 600;
      font-size: 24px !important;
      text-transform: uppercase;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      :deep(.text-h3) {
        font-size: 18px !important;
      }
    }
    :deep(.v-expansion-panel) {
      background-color: var(--v-grey-lighten1) !important;
      border: 1px solid var(--v-grey-lighten1);
      padding: 0;
      border-radius: $border-radius-root;
      margin-bottom: 0px;
      &.v-expansion-panel--active {
        border-radius: $border-radius-root;
      }
      &:last-child {
        margin-bottom: 0px;
        border-top: none;
      }
      &::before {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
      }
      .v-expansion-panel-header {
        background-color: var(--v-grey-lighten1);
        border-radius: $border-radius-root !important;
        padding-left: 0px;
        label {
          display: flex;
          align-items: center;
          padding-left: 20px;
        }
        .payment-method-img {
          height: 50px !important;
        }
      }
      .v-expansion-panel-content__wrap {
        padding: 12px 24px !important;
        border-radius: $border-radius-root !important;

        .prebilled-amount {
          margin-bottom: 20px;
        }
        .caption {
          margin-top: 20px;
        }
      }

      .prebilledAmountInfo {
        margin-bottom: 20px;
        .text {
          color: var(--v-primary-lighten1);
        }
        .sub {
          font-size: 13px;
        }
      }
      label {
        text-transform: none;
        font-weight: 600;
      }
      label:before {
        top: 15px;
        background: transparent;
      }
      label:after {
        top: 20px;
      }
    }
    :deep(.v-expansion-panel::after) {
      display: none;
    }
  }
  .other-informations {
    h3 {
      font-weight: 600;
      font-size: 24px;
    }
    label {
      font-weight: 600;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
    :deep(.v-input__slot) {
      box-shadow: none !important;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      h3 {
        font-size: 18px;
      }
    }
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";
import PaymentInvoice from "@/components/payment/PaymentInvoice.vue";
import PortInformations from "@/components/payment/PortInformations.vue";
import CollezionamentoSlider from "@/components/product/CollezionamentoSlider.vue";
import PackagesTypeList from "@/components/payment/PackagesTypeList.vue";

import AnalyticsService from "~/service/analyticsService";

import productMixins from "~/mixins/product";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";

import { requiredValue, isEmail } from "~/validator/validationRules";

import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Checkout",
  mixins: [login, productMixins, cartInfo, categoryMixins],
  components: {
    CategoryTitle,
    CheckoutSummary,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    PaymentTypeList,
    PaymentInvoice,
    CollezionamentoSlider,
    PackagesTypeList,
    PortInformations
  },
  data() {
    return {
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      error: null,
      loading: false,
      billingFormCompletedInternal: true,
      portInfoFormCompletedInternal: true,
      emailKey: 1,
      showAlternativesTooltip: false,
      valid: false,
      sellCondition: false
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      user: "cart/getUser"
    }),
    categoryDescription() {
      return this.$ebsn.meta(this.category, "category_info.DESCRIPTION");
    },
    fullAddress() {
      return (
        this.user.billingAddress.address1 +
        ", " +
        this.user.billingAddress.postalcode +
        " " +
        this.user.billingAddress.city +
        " (" +
        this.user.billingAddress.province +
        ")"
      );
    }
  },

  methods: {
    ...mapActions({
      lockCart: "cart/lockCart",
      setCartInfo: "cart/setCartInfo"
    }),
    billingFormCompleted(completed) {
      this.billingFormCompletedInternal = completed;
    },
    portInfoFormCompleted(completed) {
      this.portInfoFormCompletedInternal = completed;
    },
    processOnboardChange(name, value) {
      this.setCartInfo({ [name]: value });
    },
    async goToPayment() {
      let vm = this;
      vm.loading = true;
      try {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            await vm.lockCart();
            vm.$router.push({
              name: "Payment"
            });
          }
        }
      } catch (err) {
        await this.needTimeslot();
        vm.error = err.message;
      } finally {
        vm.loading = false;
      }
    }
  },
  mounted() {
    AnalyticsService.beginCheckout(this.cart);
  }
};
</script>
