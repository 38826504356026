import axios from "~/plugins/axios";

export default {
  getPaymentTypeList(orderId) {
    return axios
      .get("/ebsn/api/payment-type/list", { params: { order_id: orderId } })
      .then(data => {
        return data.data.data.payments;
      });
  },
  getPaymentsAuth(paymentTypeId) {
    return axios
      .get("/ebsn/api/payment-type/list-auth", {
        params: { payment_type_id: paymentTypeId }
      })
      .then(data => {
        return data.data.data.auths;
      });
  },
  deletePaymentAuth(paymentAuthId) {
    return axios
      .post("/ebsn/api/payment-type/delete-auth", null, {
        params: { payment_auth_id: paymentAuthId }
      })
      .then(data => {
        return data.data.data.auths;
      });
  },
  addAuth(paymentTypeId, paymentData) {
    return axios
      .post("/ebsn/api/payment-type/add-auth", null, {
        params: {
          payment_type_id: paymentTypeId,
          payment_data: JSON.stringify(paymentData)
        }
      })
      .then(data => {
        return data.data.data;
      });
  }
};
