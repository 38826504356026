<template>
  <div
    v-if="packages && packages.length"
    class="grey lighten-1 pa-4 mt-6 rounded-lg"
  >
    <div class="text-h3 text-uppercase">
      {{ $t("checkout.packageAddTitle") }}
    </div>

    <v-radio-group class="w-100" mandatory v-model="packagingTypeId">
      <v-radio
        v-for="pck in packages"
        :key="pck.productId"
        :value="'' + pck.productId"
        color="default"
        class="my-4"
        :aria-label="`$t('checkout.packageAddTitle'): ${pck.name}`"
        role="radio"
      >
        <template v-slot:label>
          <div
            class="d-flex align-start align-sm-center flex-column flex-sm-row justify-space-between w-100"
          >
            <div class="d-flex">
              <img
                :src="`/img_layout/packaging_type/${pck.productId}.svg`"
                :alt="pck.name"
                width="55px"
                class="mr-3"
              />

              <div class="packaging-descr align-self-center">
                <span class="d-block font-weight-bold text-body-2 mb-2">{{
                  pck.name
                }}</span>
                <span class="d-block text-caption">{{ pck.description }}</span>
              </div>
            </div>
            <div class="font-weight-bold ml-auto">{{ pck.shortDescr }}</div>
          </div>
        </template>
      </v-radio>
    </v-radio-group>
    <div v-if="showTermal">
      <p>{{ $t("checkout.packageSpecial") }}</p>
      <v-checkbox
        v-model="packagingThermo"
        color="black"
        hide-details
        true-value="true"
        false-value="false"
        ><template v-slot:label>
          <div
            class="d-flex align-start align-sm-center flex-column flex-sm-row justify-space-between w-100"
          >
            <div class="d-flex">
              <img
                :src="`/img_layout/packaging_type/termica.svg`"
                alt="termica"
                width="55px"
                class="mr-3"
              />

              <div class="packaging-descr align-self-center">
                <span class="d-block font-weight-bold text-body-2 mb-2">{{
                  $t("checkout.packageTermal")
                }}</span>
                <span class="d-block text-caption">{{
                  $t("checkout.packageTermalSize")
                }}</span>
              </div>
            </div>
            <div class="font-weight-bold ml-auto">1,00€ cad</div>
          </div>
        </template></v-checkbox
      >
    </div>
  </div>
</template>
<style scoped lang="scss">
.text-h3 {
  font-weight: 600;
  font-size: 24px !important;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .text-h3 {
    font-size: 18px !important;
  }
}
</style>

<script>
import analyticsService from "@/commons/service/analyticsService";
import cartService from "~/service/cartService";
import { mapCartInfo } from "~/service/ebsn";

export default {
  name: "PackagesTypeList",
  data() {
    return { packages: [] };
  },
  props: {
    showTermal: { type: Boolean, default: true }
  },
  computed: {
    ...mapCartInfo({
      packagingTypeId: "package_type",
      packagingThermo: "package_thermo"
    })
  },
  methods: {
    async fetchPackages() {
      this.packages = await cartService.getPackages();
    }
  },
  mounted() {
    this.fetchPackages();
  },
  watch: {
    packagingTypeId(val, oldVal) {
      if (val !== oldVal) {
        const packageName = this.packages.find(
          packageItem =>
            packageItem.productId.toString() === this.packagingTypeId
        );
        if (packageName && packageName != "") {
          analyticsService.trackEvent("", "package", packageName.name);
        }
      }
    },
    packagingThermo(val, oldVal) {
      if (val !== oldVal) {
        analyticsService.trackEvent(
          "",
          "thermal_packaging",
          JSON.parse(this.packagingThermo)
        );
      }
    }
  }
};
</script>
