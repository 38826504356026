<template>
  <div class="port-informations grey lighten-1 pa-4 mt-6 rounded-lg">
    <h3 class="my-1 text-uppercase">{{ $t("payment.portInfo") }}</h3>
    <v-form ref="form" v-model="valid" class="mt-4">
      <v-row>
        <v-col cols="12" sm="4">
          <!-- Onboard phone -->
          <label v-html="`${$t('paymentInvoice.onboard_phone')}` + '*'" />
          <v-text-field
            :value="cart.cartInfos.onboard_phone"
            :rules="phoneRule"
            :placeholder="`${$t('paymentInvoice.onboard_phone')}`"
            hide-details="auto"
            solo
            @blur="processOnboardChange('onboard_phone', $event.target.value)"
          /> </v-col
        ><v-col cols="12" sm="4">
          <!-- Pier number -->
          <label v-html="`${$t('paymentInvoice.onboard_pier_number')}` + '*'" />
          <v-text-field
            :value="cart.cartInfos.onboard_pier_number"
            :rules="[requiredRules()]"
            :placeholder="`${$t('paymentInvoice.onboard_pier_number')}`"
            hide-details="auto"
            solo
            @blur="
              processOnboardChange('onboard_pier_number', $event.target.value)
            "
          />
        </v-col>
        <v-col cols="12" sm="4">
          <!-- Boat number -->
          <label v-html="`${$t('paymentInvoice.onboard_boat_number')}` + '*'" />
          <v-text-field
            :value="cart.cartInfos.onboard_boat_number"
            :rules="[requiredRules()]"
            :placeholder="`${$t('paymentInvoice.onboard_boat_number')}`"
            hide-details="auto"
            solo
            @blur="
              processOnboardChange('onboard_boat_number', $event.target.value)
            "
          />
        </v-col>
        <v-col cols="12">
          <!-- Notes -->
          <label v-html="`${$t('paymentInvoice.notes')}`" />
          <v-text-field
            :value="cart.cartInfos.notes"
            :placeholder="`${$t('paymentInvoice.notes')}`"
            hide-details="auto"
            solo
            @blur="processOnboardChange('notes', $event.target.value)"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<style lang="scss">
.port-informations {
  h3 {
    font-weight: 600;
    font-size: 24px;
  }
  label {
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  .v-input__slot {
    box-shadow: none !important;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    h3 {
      font-size: 18px;
    }
  }
}
</style>

<script>
import { mapCartInfo } from "~/service/ebsn";
import { mapState, mapActions } from "vuex";

import { requiredValue, isTelephone } from "~/validator/validationRules";

export default {
  name: "PortInformations",
  data() {
    return {
      valid: false,
      phoneRule: [requiredValue(), isTelephone()],
      requiredRules: requiredValue
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapCartInfo({ onboard_phone: "onboard_phone" })
  },
  methods: {
    ...mapActions({
      setCartInfo: "cart/setCartInfo"
    }),
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          _this.$emit("portInfoCompleted", _this.valid);
        }
      }, 200);
    },
    processOnboardChange(name, value) {
      this.setCartInfo({ [name]: value });
      this.validate();
    }
  },
  async mounted() {
    this.validate();
  }
};
</script>
