<template>
  <div
    class="payment-type-list-container payment-methods mt-5 py-3 rounded-sm"
    :style="{ 'background-color': options.mainBackgroundColor || 'white' }"
  >
    <div
      v-if="!isProfileView"
      :class="
        options.titleStyle
          ? options.titleStyle
          : 'text-h3 secondary--text mb-5 font-weight-bold text--lighten-1'
      "
    >
      {{ $t("paymentTypeList.title") }}
    </div>
    <i18n path="paymentTypeList.description" tag="p" v-if="!isProfileView" />
    <v-alert v-if="isPaymentDisabled" type="error" dense>
      {{ $t("paymentTypeList.disabled") }}
    </v-alert>
    <template v-else>
      <v-expansion-panels
        accordion
        :flat="options.flatPanels"
        v-model="selectedPayment"
        :disabled="isPaymentDisabled"
      >
        <v-expansion-panel
          v-for="(paymentType, index) in paymentTypeList"
          v-bind:key="paymentType.paymentTypeId"
          :style="{
            'background-color': options.cardBackgroundColor || 'white'
          }"
          :aria-controls="'panel-content-' + paymentType.paymentTypeId"
          role="button"
        >
          <v-expansion-panel-header
            hide-actions
            v-on:click.prevent="setPaymentInfo(paymentType.name)"
            :aria-controls="'panel-content-' + paymentType.paymentTypeId"
            role="button"
            :aria-expanded="
              selectedPayment === paymentType.paymentTypeId ? 'true' : 'false'
            "
          >
            <input
              type="radio"
              :aria-label="
                (paymentType && paymentType.name) || 'Tipo di pagamento'
              "
              role="radio"
              :id="'test' + paymentType.paymentTypeId"
              name="radio-group"
              :checked="selectedPayment === index ? true : false"
              :data-test="paymentType.gatewayClient"
            />

            <label
              class="payment-method-header-label"
              :for="'test' + paymentType.paymentTypeId"
            >
              <v-img
                v-if="options.showPaymentMethodImage"
                :src="`/paymentmethod/${paymentType.paymentTypeId}.svg`"
                contain=""
                eager
                onerror="this.onerror=null;this.src=''"
                height="37"
                max-width="60"
                class="mx-3 payment-method-img"
                alt="Metodo di pagamento"
              />
              {{ paymentType.name }}

              <v-row
                class="images-payment-type"
                no-gutters
                align="center"
                :justify="options.cardsPosition || 'end'"
                :class="
                  $vuetify.breakpoint.xs
                    ? `mobile images-payment-type-${paymentType.paymentTypeId}`
                    : `images-payment-type-${paymentType.paymentTypeId}`
                "
              >
                <template
                  v-if="
                    options.showCardLogos &&
                      [10, 12, 22].includes(paymentType.paymentTypeId)
                  "
                >
                  <!-- CREDIT CARDS -->
                  <v-img
                    v-if="isAmexAccepted"
                    eager
                    src="/credit-card/amex-curved-64px.png"
                    alt="American Express"
                    height="37"
                    max-width="60"
                    class="mr-1"
                  ></v-img>
                  <v-img
                    eager
                    src="/credit-card/mastercard-curved-64px.png"
                    alt="Mastercard"
                    height="37"
                    max-width="60"
                    class="mr-1"
                  ></v-img>
                  <v-img
                    eager
                    src="/credit-card/electron-curved-64px.png"
                    alt="Visa Electron"
                    height="37"
                    max-width="60"
                    class="mr-1"
                  ></v-img>
                  <v-img
                    eager
                    src="/credit-card/visa-curved-64px.png"
                    alt="Visa"
                    height="37"
                    max-width="60"
                  >
                  </v-img>
                </template>
                <template
                  v-if="
                    options.showCardLogos &&
                      [13, 27].includes(paymentType.paymentTypeId) &&
                      !$vuetify.breakpoint.xs
                  "
                >
                  <!-- PAYPAL -->
                  <v-img
                    eager
                    src="/credit-card/paypal-transparent-64px.png"
                    alt="Paypal"
                    height="37"
                    max-width="90"
                  ></v-img>
                </template>
                <template
                  v-if="
                    options.showCardLogos &&
                      [23].includes(paymentType.paymentTypeId)
                  "
                >
                  <!-- SATISPAY -->
                  <v-img
                    eager
                    :src="
                      `/img_layout/payment_${paymentType.paymentTypeId}.png`
                    "
                    alt="Satispay"
                    height="37"
                    max-width="120"
                    class="mr-1"
                  ></v-img>
                </template>
              </v-row>
            </label>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div :class="{ 'payment-disabled': isPaymentDisabled }">
              <GatewayWriter
                :payment-type="paymentType"
                :order-id="orderId"
                :component-name="paymentType.gatewayClient"
                :mode="mode"
                :options="mergedOptions"
                :isProfileView="isProfileView"
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </div>
</template>

<script>
import GatewayWriter from "~/components/payment/gateways/GatewayWriter.vue";
import PaymentTypeService from "~/service/paymentTypeService";

import merge from "lodash/merge";
import { mapState } from "vuex";
import AnalyticsService from "~/service/analyticsService";

export default {
  name: "PaymentTypeList",
  props: {
    orderId: { type: Number, required: false },
    isPaymentDisabled: { type: Boolean, default: false },
    defaultPaymentType: { type: Number, required: false, default: 0 },
    options: { type: Object, required: false, default: () => ({}) },
    autoSelectPaymentType: { type: Boolean, default: true },
    filteredPaymentTypeIds: { type: Array, required: false },
    modality: { type: String, required: false },
    isProfileView: { type: Boolean, default: false },
    paymentNameToPaymentInfo: { type: Boolean, default: false },
    showAddNewCardWarningInfo: { type: Boolean, required: false, default: true }
  },
  components: { GatewayWriter },
  data() {
    return {
      selectedPayment: null,
      paymentTypeList: []
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    mode() {
      return this.modality ? this.modality : this.orderId ? "order" : "cart";
    },
    mergedOptions() {
      return merge(
        {
          showRememberCard: true,
          showPaymentMethodImage: false,
          showCardLogos: true,
          showAddNewCardWarning: this.showAddNewCardWarningInfo,
          confirm: {
            show: !this.isProfileView,
            label: this.$t("paymentTypeList.confirm"),
            color: "primary",
            outline: false,
            icon: null,
            link: false,
            large: true,
            depressed: true
          },
          back: {
            show: !this.isProfileView,
            label: this.$t("common.back"),
            color: "primary",
            outline: true,
            icon: null,
            link: false,
            large: true,
            depressed: true
          }
        },
        this.options
      );
    },
    isAmexAccepted() {
      return global.config?.isAmexAccepted;
    }
  },
  methods: {
    fetchPaymentTypeList() {
      PaymentTypeService.getPaymentTypeList(this.orderId, true).then(
        paymentTypeList => {
          this.paymentTypeList = this.filteredPaymentTypeIds
            ? paymentTypeList.filter(paymentType =>
                this.filteredPaymentTypeIds.includes(paymentType.paymentTypeId)
              )
            : paymentTypeList;
          if (this.autoSelectPaymentType) {
            const paymentTypeId = this.$store.state.cart.cart.paymentTypeId;
            // const paymentTypeId = 12 // hardcoded for testing purposes
            const paymentTypeIndex = paymentTypeList.findIndex(
              paymentType => paymentType.paymentTypeId === paymentTypeId
            );
            this.selectedPayment =
              paymentTypeIndex !== -1
                ? paymentTypeIndex
                : this.defaultPaymentType;
            if (this.paymentTypeList.length) {
              this.setPaymentInfo(
                this.paymentTypeList[this.selectedPayment].name
              );
            }
          }
        }
      );
    },
    setPaymentInfo(name) {
      AnalyticsService.checkoutOptions(2, "payment_method", name);
      AnalyticsService.addPaymentInfo(
        this.cart,
        this.paymentNameToPaymentInfo ? name : undefined
      );
      AnalyticsService.trackEvent(null, "payment_method", name);
    }
  },
  created() {
    this.fetchPaymentTypeList();
  }
};
</script>
<style lang="scss">
.payment-type-list-container {
  .v-expansion-panel-header {
    .payment-method-header-label {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .mobile.images-payment-type {
        display: none;
      }
    }
  }
}
</style>
