<template>
  <div>
    <div
      class="slider product-slider collezionamento-slider pa-2 pb-3 pb-sm-6"
      :class="this.cols == 'auto' ? 'slider-auto' : 'product-grid'"
      v-if="products && products.length > 0"
    >
      <slot><h2 v-html="title"></h2></slot>
      <swiper :options="swiperOption" ref="swiperRef">
        <swiper-slide
          v-for="product in availableProducts"
          :key="product.productId"
        >
          <CollezionamentoCard
            v-bind:product="product"
            @selectProduct="$emit('selectProduct', { product: product })"
          />
        </swiper-slide>
      </swiper>
      <swiper-paginator
        :showBullets="showBullets"
        :showArrows="showArrows"
        :paginationClass="paginationClass"
        :length="availableProducts.length"
      />
      <div v-if="showMore" class="text-right show-more">
        <v-btn
          link
          text
          class="primary--text text-none "
          :to="
            categoryPath
              ? {
                  name: 'Category',
                  params: { pathMatch: categoryPath },
                  query: { categoryId: categoryId }
                }
              : {
                  name: 'Category',
                  params: {
                    pathMatch: proposal.slug
                  }
                }
          "
        >
          mostra tutti <v-icon>$chevronRight</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<style global lang="scss">
.collezionamento-slider {
}
</style>
<script>
import CollezionamentoCard from "@/components/product/CollezionamentoCard.vue";
import ProductService from "~/service/productService";
import analyticsService from "@/commons/service/analyticsService";
import SwiperPaginator from "../SwiperPaginator.vue";

export default {
  name: "CollezionamentoSlider",
  props: {
    productList: { type: Array, required: false },
    query: { type: String, required: false },
    layout: { type: Number, required: false },
    shuffle: { type: Boolean, required: false, default: false },
    timeslotId: { type: String, required: false },
    userId: { type: Number, required: false },
    warehouseId: { type: Number, required: false },
    limit: { type: Number, required: false, default: 12 },
    title: { type: String, required: false },
    proposal: { type: Object, required: false },
    paginationClass: { type: String, required: false },
    cols: { default: 1 },
    sm: { default: 3 },
    md: { default: 2 },
    lg: { default: 4 },
    xl: { default: 6 },
    showArrows: { type: Boolean, default: false },
    showBullets: { type: Boolean, default: true }
  },
  components: {
    CollezionamentoCard,
    SwiperPaginator
  },
  data() {
    return {
      products: [],
      showMore: false,
      swiperOption: {
        slidesPerGroup: 1,
        spaceBetween: 20,
        watchOverflow: true,
        autoplay: false,
        pagination: {
          el: `#pagination-${this.paginationClass}`,
          clickable: true
        },
        navigation: {
          prevEl: `#prev-${this.paginationClass}`,
          nextEl: `#next-${this.paginationClass}`
        },
        breakpoints: {
          0: {
            slidesPerView: this.cols
          },
          600: {
            slidesPerView: this.sm
          },
          960: {
            slidesPerView: this.md
          },
          1264: {
            slidesPerView: this.lg
          },
          1900: {
            slidesPerView: this.xl
          }
        }
      }
    };
  },
  async created() {
    // fetch the data when the view is created and the data is
    // already being observed
    await this.fetchProducts();
    if (!this.products || this.products.length < 1) {
      this.$emit("emptyslider");
    }
  },
  computed: {
    availableProducts() {
      return this.products.filter(
        product =>
          (product.priceDisplay && product.priceDisplay > 0.01) ||
          product.warehousePromo
      );
    }
  },
  methods: {
    stopAutoplay() {
      this.$refs.swiperRef.swiperInstance.autoplay.stop();
    },
    startAutoplay() {
      if (!this.$refs.swiperRef.swiperInstance.autoplay.running) {
        this.$refs.swiperRef.swiperInstance.autoplay.start();
      }
    },
    handleClick(payload) {
      analyticsService.clickProduct(
        payload.product,
        this.position,
        payload.index
      );
    },
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting) {
        analyticsService.viewProducts(this.products, this.position);
      }
    },
    async fetchProducts() {
      this.products = [];
      if (this.productList) {
        this.products = this.productList;
      } else if (this.query) {
        let response = await ProductService.search({
          q: this.query,
          page_size: 16
        });
        this.products = response.products;
      } else {
        let response = await ProductService.adv({
          layout: this.layout,
          timeslotId: this.timeslotId,
          userId: this.userId,
          warehouseId: this.warehouseId,
          limit: this.limit,
          shuffle: this.shuffle
        });
        if (response) {
          this.products = response.products;
        }
        this.$emit("loaded", this.products ? this.products.length : 0);
      }
    }
  }
};
</script>
